<template>
  <div>
    <a href="#" class="btn btn-primary float-end btn-sm" @click.prevent="show_add_item = true"><i class="lni lni-plus me-2"></i>Add New</a>
    <h3>Documents</h3>
    <loading v-if="loading" />
    <template v-if="! loading">
      <table class="table table-bordered bg-white">
        <thead>
          <tr>
            <th width="20px">#</th>
            <th width="60%">Name</th>
            <th>Default</th>
            <th>Document</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(doc, i) in data" :key="`doc-${i}`">
            <td>{{ i+1 }}</td>
            <td>{{ doc.title }}</td>
            <td>{{ doc.default ? 'Yes' : 'No' }}</td>
            <td><a :href="doc.url" class="btn btn-primary btn-sm" target="_blank"><i class="lni lni-download me-2"></i>Download</a></td>
            <td><a href="#" @click.prevent="removeItem(i)" class="text-danger"><i class="lni lni-trash-can"></i></a></td>
          </tr>
          <tr v-if="data.length == 0">
            <td colspan="5">There is no data to display.</td>
          </tr>
        </tbody>
      </table>
    </template>
    <modal :show="show_add_item" @close="show_add_item = false">
      <template slot="header">Add FAQ Item</template>
      <form @submit.prevent="submit" v-if="! loading_submitting">
        <div class="form-group">
          <label>Title</label>
          <input type="text" required v-model="new_item.title" class="form-control" placeholder="Title">
        </div>
        <div class="form-group">
          <label>Document</label>
          <input type="file" required ref="add_document" class="form-control" placeholder="Document">
        </div>
        <div class="form-group">
          <input id="is-default" class="me-3" type="checkbox" v-model="new_item.default" :value="1">
          <label for="is-default">Default (Is Terms and Conditions)</label>
        </div>
        <div class="form-group">
          <button class="btn btn-primary"><i class="lni lni-plus me-2"></i>Add</button>
        </div>
      </form>
      <loading v-if="loading_submitting" />
    </modal>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      data: [],
      loading: true,
      show_add_item: false,
      new_item: { default: 0 },
      loading_submitting: false
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get("/api/v1/admin/content/documents").then((response) => {
        this.data = response.data.content;
        this.loading = false
      });
    },

    submit() {
      this.loading_submitting = true

      let data = new FormData
      data.append('content', 'add')
      data.append('document', this.$refs.add_document.files[0])
      data.append('title', this.new_item.title)
      data.append('default', this.new_item.default)
      data.append('_method', 'PUT')

      this.$axios.post('/api/v1/admin/content/documents', data).then(() => {
        this.fetch()
        this.show_add_item = false
        this.loading_submitting = false
      })
    },

    removeItem(index) {
      let data = {
        content: 'delete',
        index: index
      }

      this.$axios.put('/api/v1/admin/content/documents', data).then(() => {
        this.fetch()
      })
    }
  }
}
</script>